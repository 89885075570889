<template>
  <div>
    <PageMenu :breadcrumbs="breadcrumbs" :page="page"/>

    <div class="page-with-menu">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Details" active>
            <b-card-text>
              <FormTemplate v-if="form" :form="form">
                  <b-form-group class="label-for-xl" label="String" label-for="inputPlaceholder">
                      <b-form-textarea
                          type="text" id="inputItems" class="form-control form-control-xl"
                          placeholder="enter the permissions"
                          v-model="form.data.items"
                          :state="form.states.items"
                          rows="20"
                      />
                      <b-form-invalid-feedback v-if="form.errors">{{ form.errors.items }}</b-form-invalid-feedback>
                  </b-form-group>
              </FormTemplate>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import PageMenu from '@/common/components/PageMenu';
import {Form} from '@/common/utils/parnekt';
import FormTemplate from '@/common/components/Form/Form';

export default {
    components: {
      PageMenu,
      FormTemplate
    },
    data(){
      return {
        page: {
            title: "Create Bulk Permissions",
        },
        breadcrumbs: [
            {
                title: "Dashboard",
                url: this.$router.resolve({name: "app.dashboard"}).href
            },
            {
                title: "Users",
                url: this.$router.resolve({name: "users"}).href
            },
            {
                title: "Permissions",
                url: this.$router.resolve({name: "users.permissions"}).href
            },
            {
                title: "Create Bulk Permission",
            }
        ],
        form: null
      }
    },
    mounted(){
      this.form = new Form({items: ""},{
        onSubmit: (form) => {
          return this.$api.post(`permission/bulk`,{
            data: {permissions: form.get('items')}
          });
        },
        onSuccess: () => {
          this.$router.push({name: "users.permissions"})
        }
      });

      this.$emit('set-sidebar',{active: "users"});
    },
};
</script>
